*{
    overflow-x: none;
}

.Works{
    display: flex;
    padding-top: 0px ;
    padding-left: 100px;  
}
.Works h1{
    color:  rgb(86, 136, 252);
}

.caixa_vazia{
    width: 20%;
    padding: 20px 100px;
}

.Carreira{
    width: 70%;
    padding: 55px;
    
}

.title{
    font-size: 20px;
    padding-left: 20px;
    padding-bottom: 50px ;
    
}

.Freelancer{
    background-color: transparent;
    width: 100%;
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
}

.Freelancer{
    padding:0 20px ;
}

.Freelancer h3{
    background-color: transparent;
}

.datas{
    padding: 20px 0;
    background-color: transparent;
    width: 200px;
    border-right: 1px solid #eee;
}


.experience{
    width: 100%;
    padding: 20px 25px;
    
}

.experience p {
    background-color: transparent;
    color: #ccc;
    margin: 20px 0;
}
.experience:hover{
    box-shadow: 0px 0px 60px .1px rgba(53, 53, 146, 0.342);
    border-bottom: solid 1px #232649 ;
    background-color: rgba(101, 141, 235, 0.089);
}

/* Responsive Styles */
@media screen and (max-width:1260px) {
    .caixa_vazia{
        display: none;
    }
    .Works{
        padding: 0 20px;
    }
    .Carreira{
        width: 100%;
        padding: 0;
    }
    
 }

 @media screen and (max-width: 700px){
    .datas{
        border-right: 0px transparent;
        text-align: justify;
        padding: 0;
        width: 100%;
    }
    .datas h3{
        border-left: 1px solid rgb(86, 136, 252);;
        margin: 0;
        padding: 0 10px;
    }

    .Freelancer{
        padding: 20px 0;
        flex-direction: column;
        border-bottom: 1px solid #ccc;
    }
 }
     

