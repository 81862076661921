*{
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #171b20;
  color: white;
  overflow-x: none;
}

@media screen and (max-width:1260px) {
  .App{
      display: flex;
      flex-direction: column;
  }
}