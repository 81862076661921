*{
    overflow-x: none;
}

/*Criando os estilos do cabeçalho*/
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 10px 0px 0px;
    text-align: center;
}

/*Criando os estilos da lista menu*/
.header .list{
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.list ul{
    position:absolute;
    display: flex;
    text-align: center;
}

.list li{
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 0px 10px;
    text-align:center ;
}

.list a{
    border-left: 1px solid #ccc;
    list-style: none;
    text-decoration: none;
    cursor: pointer;
}

/*Estilizando Botões*/

.btn-cv{
    display: flex;
    flex-direction: column;
    padding-right:  160px;
}


.btn-cv button{
    width: 130px;
    padding: 16px;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(90deg, rgb(86, 136, 252) 0%,
    rgb(22, 87, 165)100%);
    border-radius: 10px;
    font-weight: bold;
}

.btn-cv button:hover{
    background: linear-gradient(90deg, rgb(22, 87, 165) 0%,
    rgb(86, 136, 252) 100%);
}

.ajuste_btn{
    text-align: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
    align-items: center;
    cursor: pointer;
}

.btn-cv1 button,
.btn-cv2 button{
    margin: 2px 0;
    width: 90px;
    padding: 5px;
}

.btn-cv1 a,
.btn-cv2 a{
    cursor: pointer;
}




/*Criando os estilos da logo*/

.header .logo img{
    width: 30px;
}

@media screen and (max-width:830px) {
    .header{
        display: flex;
        padding: 40px 0 ;
        justify-content:space-around;
    }
    .btn-cv{
        padding-right:  10px;
    }
    
}

@media screen and (max-width:500px) {
    .header{
        flex-direction: column;
    }
    .btn-cv{
        padding-top:50px;
    }
    
    
}


