*{
    overflow-x: none;
}

/* Base Styles */
.container {
    display: flex;
    padding: 0 40px; /* Reduced padding for smaller screens */
    font-weight: 0;
}

.context {
    background-color: transparent;
    margin-left: 35px;
    text-align: center;
    position: fixed;
    top: 80px;
    left: 0;
    padding: 60px 50px;
}

.foto img {
    width: 100px; /* Adjusted for smaller screens */
}

.line {
    margin: 10px 0;
    width: 100%;
    border: 1px solid #ccc;
}

.meu_nome {
    display: flex;
    text-align: center;
    color: rgb(128, 0, 255);
    padding: 0 20px; /* Adjusted for smaller screens */
}

.meu_nome img {
    width: 35px;
    margin-right: 5px;
}

.social {
    display: flex;
    justify-content: space-around; /* Adjusted for smaller screens */
    padding: 15px 0;
}

.social img {
    width: 25px; /* Adjusted for smaller screens */
}

.social a:hover {
    color: blue;
}

[aria-label] {
    position: relative;
}

[aria-label]::after {
    content: attr(aria-label);
    display: none;
    position: absolute;
    top: 115%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5000;
    pointer-events: none;
    padding: 6px 10px;
    white-space: nowrap;
    font-size: 1rem;
    color: #fff;
    background: linear-gradient(90deg, rgb(86, 136, 252) 0%, rgb(14, 59, 114) 100%);
    text-align: center;
    border-radius: 30px;
}

[aria-label]:hover::after {
    display: block;
}

.menu .MN {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.MN ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: left;
    text-align: left;
}

.MN li {
    width: 100%;
    list-style: none;
    margin: 10px 0;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 10px;
    text-align: left;
}

.MN a {
    text-decoration: none;
    color: inherit;
}

.MN img {
    width: 30px;
    margin-right: 5px;
    background-color: transparent;
}

.MN li:hover{
    background-color: rgba(86, 136, 252, 0.137);
}



/* Responsive Styles */

@media screen and (max-width:1260px) {
   
    .container{
     padding: 10px 0px;
    }
    .context{
    position: relative;
     width: 100%;
     padding: 0px 0px;
     margin: 0px;
    }
    .meu_nome{
     text-align: center;
     display: flex;
     justify-content: center;
     padding: 5px 0 !important;
    }
 
    .social{
     display: flex;
     justify-content: center; /* Adjusted for smaller screens */
     padding: 15px 2px;
     margin: 0 10px;
    }
 
    .social img{
     margin: 0 10px;
    }
 
     .MN {
     display: flex;
     flex-direction: row;
     align-items: center;
     padding: 10px 0;
     text-align: left;
 }
     .MN ul {
     display: flex;
     flex-direction: row;
     padding:0 20px;
 }
     .MN li{
         padding: 0 20px;
 }
    
 }

 @media screen and (max-width:700px) {
    .MN li {
        font-size: 15px;
    }
    .foto img{
        width: 150px;
    }
    
}

@media screen and (max-width:500px) {
    .MN{
        text-align: left;
    }
    .MN ul {
        display: flex;
        flex-direction: column;
        text-align: justify;
    }
    .MN li {
        display: flex;
        text-align: justify;
    }
    .context {
        text-align: center;
        top: 10px;
        left: 0;
        padding: 10px 0px;
    }
}
 