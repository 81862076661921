*{
    overflow-x: none;
}

.Jobs{
    display: flex;
    padding-top: 0px ;
    padding-left: 100px;  
}
.Jobs h1{
    color:  rgb(86, 136, 252);
}

.caixa_vazia_Jobs{
    width: 20%;
    padding: 20px 100px;
}

.Projetos{
    width: 80%;
    padding: 50px 55px;
    
}

.title_Jobs{
    font-size: 20px;
    padding-left: 20px;
    padding-bottom: 50px ;
}

.Free_Jobs{
    background-color: transparent;
    width: 100%;
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    
}

.line{
    margin: 10px 0;
    width: 100%;
    border: .01rem solid #ccc;;
}

.Free_Jobs h3{
    background-color: transparent;
}

.datas_Jobs{
    display: flex;
    padding: 20px 0;
    background-color: transparent;
    width: 200px;
    align-items: center;
}

.datas_Jobs img{
    cursor: pointer;
    width: 300px;
}

.datas_Jobs img:hover{
    scale: (1);
    box-shadow: 0px 0px 60px .1px rgba(53, 53, 146, 0.342);
    border-bottom: solid 1px #232649 ;
    background-color: rgba(101, 141, 235, 0.089);
}

.experience_Jobs{
    width: 70%;
    padding: 20px 10px;
    border-left: #ccc 1PX solid;

}

.experience_Jobs p {
    font-size: 16px;
    background-color: transparent;
    color: #ccc;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    text-align: justify;
    line-height: 25px;
}
/*
.experience_Jobs:hover{
    box-shadow: 0px 0px 60px .1px rgba(53, 53, 146, 0.342);
    border-bottom: solid 1px #232649 ;
    background-color: rgba(101, 141, 235, 0.089);
}
*/

.experience_Jobs h3:hover{
    color:rgb(48, 127, 224);
}

.experience_Jobs a{
    text-decoration: none;
    background-color: transparent;
}

.tecnology{
    padding: 20px 5px;
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
}

.tecnology span{
    margin:20px 10px;
    background: linear-gradient(90deg,rgb(14, 59, 114)0%,
    rgb(86, 136, 252) 100%);
    padding: 12px 20px;
    border-radius: 50px;
}

.tecnology span:hover{
    background: linear-gradient(90deg,rgb(86, 136, 252) 0%,
    rgb(14, 59, 114) 100%);
}

.experience_Jobs:hover{
    box-shadow: 0px 0px 60px .1px rgba(53, 53, 146, 0.342);
    border-left: solid 1px #616be0 ;
    background-color: rgba(101, 141, 235, 0.089);
}


@media screen and (max-width: 1260px){
    .caixa_vazia_Jobs{
        display: none;
    }
    .Jobs{
        width: 100%;
        padding-left: 0px;
    }
    .Projetos{
        margin: 0;
        padding: 0 10px;
        width: 100%;
    }
    .datas_Jobs{
        padding: 0px;
    }
}

@media screen and (max-width: 1120px){
    .Projetos{
        margin-top: 100px;
    }
    .Free_Jobs{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: justify;
    }
    .experience_Jobs{
        width: 100%;
        margin-top: 10px;
        padding: 10px;
    }
    .datas_Jobs{
        border-left: 1px solid #616be0;
    }
    
    .datas_Jobs img{
        padding: 10px 10px;
        width: 100%;
    }
    
}
@media screen and (max-width: 700px) {
    .experience_Jobs{
        border-bottom: 1px solid rgb(255, 255, 255);
    }
}
