*{
    overflow-x: none;
}

.Schools{
    display: flex;
    padding-top: 0px ;
    padding-left: 100px;  
    
}
.Schools h1{
    color:  rgb(86, 136, 252);
}

.caixa_vazia_school{
    width: 20%;
    padding: 20px 100px;
}

.Estudos{
    width: 70%;
    padding: 50px 55px;
}

.title_school{
    font-size: 20px;
    padding-left: 20px;
    padding-bottom: 50px ;
}

.Free_School{
    background-color: transparent;
    width: 100%;
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
}



.Free_School h3{
    background-color: transparent;
}

.datas_school{
    padding: 20px 0;
    background-color: transparent;
    width: 200px;
    border-right: 1px solid #eee;
}


.experience_school{
    width: 100%;
    padding: 20px 25px;
    
}

.experience_school p {
    background-color: transparent;
    color: #ccc;
    margin: 20px 0;
}
.experience_school:hover{
    box-shadow: 0px 0px 60px .1px rgba(53, 53, 146, 0.342);
    border-bottom: solid 1px #232649 ;
    background-color: rgba(101, 141, 235, 0.089);
}

.experience_school a{
    text-decoration: none;
}

/* Responsive Styles */
@media screen and (max-width:1260px) {
    .caixa_vazia_school{
        display: none;
    }
    .Schools{
        padding: 0 20px;
    }
    .Estudos{
        width: 100%;
        padding: 0;
    }
    
 }

 @media screen and (max-width: 700px){
    .datas_school{
        border-right: 0px transparent;
        text-align: justify;
        padding: 0;
        width: 100%;
    }
    .datas_school h3{
        border-left: 1px solid rgb(86, 136, 252);;
        margin: 0;
        padding: 0 10px;
    }

    .Free_School{
        padding: 20px 0;
        flex-direction: column;
        border-bottom: 1px solid #ccc;
    }
    .title_school{
        margin-top: 40px;
        padding: 40px 0;
    }
 }
     
